import React from "react"
import { FormattedMessage } from 'gatsby-plugin-intl'
import jobsStyles from "../../style/jobs.module.scss"
import Arrow from "../../img/arrow.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink"


const JobsCard = (props) => {

    return (
        <AniLink className={jobsStyles.link} cover bg="#F2F2F2" duration={1} direction="right" to={props.url}>
            <div className={jobsStyles.jobListing}>
                <h3>
                    {props.title} <br />
                </h3>
                <p><FormattedMessage id="Jobs.position.gender" /></p>
                <img className={jobsStyles.arrow} src={Arrow} alt="-->" width="100%" />
            </div>
        </AniLink>
    )
}

export default JobsCard
